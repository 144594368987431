// postSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface PostState {
  posts: any;
  loading: boolean;
  error: string | null;
}

const initialState: PostState = {
  posts: [],
  loading: false,
  error: null,
};

const postSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    fetchPostsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPostsSuccess(state, action: PayloadAction<any>) {
      state.posts = action.payload;
      state.loading = false;
    },
    fetchPostsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchPostsStart, fetchPostsSuccess, fetchPostsFailure } = postSlice.actions;

export default postSlice;

// Thunk to fetch posts
// export const createPost = (postData: Partial<any>): AppThunk => async (dispatch) => {
//   dispatch(fetchPostsStart());
//   try {
//     const newPost = await handleLogin<any>('/login', postData);
//     dispatch(fetchPostsSuccess(newPost));
//     // You can handle the response as needed, e.g., update your state
//   } catch (error:any) {
//     console.log(error?.message)
//     dispatch(fetchPostsFailure(error?.message));
//     // Handle error here
//   }
// };