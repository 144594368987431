import React, { useEffect } from "react";
import { useSelector } from "react-redux/es/exports";

function Loader() {
  const [isLoading, setisLoading] = React.useState<boolean>(true);
  const data = useSelector((state: any) => {
    return state.loader;
  });
  useEffect(() => {
    setisLoading(data.isLoading);
  }, [data]);
  return (
    <>
      <div className="loader" hidden={isLoading}>
        <svg viewBox="0 0 32 32" width="75" height="100">
          <circle id="spinner" cx="16" cy="16" r="14" fill="none"></circle>
        </svg>
      </div>
    </>
  );
}

export default Loader;
