import {  configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import LoaderSlice from "../loaderslice/Loaderslice";
import postSlice from '../apislice/Apislice';
import sidebarSlice from "../sidebarslice/SidebarSlice";
export const store = configureStore({
    reducer: {
        loader: LoaderSlice.reducer,
        login:postSlice.reducer,
        sidebar:sidebarSlice.reducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
