import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { sidebar } from "../../store/sidebarslice/SidebarSlice";
import { handleGetName, handleRemoveToken } from "../user/UserDetail";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [userName, setuserName] = React.useState<string>("");
  const [userLogo, setuserLogo] = React.useState<string>("");
  React.useEffect(() => {
    const user:string | null = handleGetName();
    if (user) {
      setuserName(user);
      const [firstName, lastName] = user.split(" ");
      setuserLogo(`${firstName? firstName?.charAt(0): ''}` + `${lastName ? lastName?.charAt(0): ''}`);
    }
  }, []);

  const logOut = () => {
    const removeToken:boolean = handleRemoveToken();
    if (removeToken) {
      navigate("/");
    }
  };
  const redirectSettingPage = () => {
    navigate("/setting");
  };

  const openSidebar = () =>{
    dispatch(sidebar())
  }

  return (
    <>
      <header>
        <div className="common-header">
          <div className="mobile-view-btn">
            <button type="button" onClick={openSidebar}><img src="/images/hamburger-icon.svg" alt="" /></button>
          </div>
          <div className="user-dropdown">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className="user-name-logo">
                  <p>{userLogo}</p>
                </div>
                {userName}
                <img
                  className="dropdown-icon"
                  src="/images/dropdown-icon.svg"
                  alt=""
                ></img>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={redirectSettingPage}>
                  <img src="/images/setting-icon.svg" alt=""></img>{t('config.setting')}
                </Dropdown.Item>
                <div className="gap-item"></div>
                <Dropdown.Item onClick={logOut}>
                  <img src="/images/logout-icon.svg" alt=""></img>{t('config.logOut')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
