import { useLocation } from "react-router";
import LoaderComponent from "../common/Loader";
import Sidebar from "../../pages/sidebar/sidebar";
import Router from "../.././routes/Router";
import Header from "../common/Header";

function Home() {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/" &&
        location.pathname !== "/register" &&
        location.pathname !== "/forgot-password" &&
        !location.pathname.includes("reset-password") && (
          <>
            <Sidebar />
            <Header />
          </>
        )}
      <div
        className={
          location.pathname !== "/" &&
          location.pathname !== "/register" &&
          location.pathname !== "/forgot-password" &&
          !location.pathname.includes("reset-password")
            ? "main-container"
            : ""
        }
      >
        <Router />
      </div>

      <LoaderComponent />
    </>
  );
}

export default Home;
