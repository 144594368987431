import React, { useEffect } from "react";
import "../src/style/base/base.scss";
import Home from "./component/home/Home";
import { I18nextProvider, useTranslation } from "react-i18next";
function App() {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    i18n.changeLanguage(`${navigator.language}`)
  }, [])
  
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <Home />
      </I18nextProvider>
    </>
  );
}

export default App;
