import { createSlice } from "@reduxjs/toolkit";


const sidebarSlice = createSlice({
    name:'sidebar',
    initialState: {
      isOpen: false,
    },
    reducers: {
      sidebar: (state) => {
        state.isOpen = !state.isOpen;
      },
      sidebarOpen:(state) => {
        state.isOpen = true
      },
      sidebarClose:(state) => {
        state.isOpen = false
      },
    },
});

export default sidebarSlice;
export const {sidebar,sidebarOpen,sidebarClose} = sidebarSlice.actions;