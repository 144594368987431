import { Route } from "react-router";
import { Routes } from "react-router-dom";
import Protected from "./Privateroute";
import { lazy, Suspense } from "react";

const Login = lazy(() => import("../pages/login/Login"));
const Register = lazy(() => import("../pages/register/Register"));
const ForgotPsw = lazy(
  () => import("../pages/forgot-password/fotgot-password")
);
const Newpassword = lazy(() => import("../pages/forgot-password/Newpassword"));
const Product = lazy(() => import("../pages/product/Product"));
const Rules = lazy(() => import("../pages/rules/Rules"));
const Setting = lazy(() => import("../pages/setting/Setting"));
const ProductDetails = lazy(() => import("../pages/product/Product-details"));
const Aco = lazy(() => import("../pages/aco/Aco"));
const WooApiIn = lazy(() => import("../pages/aco/WoocommerceIn"));
const Woocommerce = lazy(() => import("../pages/woo-commerce/Woocommerce"));
const Shopify = lazy(() => import("../pages/shopify/Shopify"));
const NoPage = lazy(() => import("../pages/404 page/Nopage"));
const ProductTable = lazy(()=>import("../pages/productTable/ProductTable"));
const ProcessLogs = lazy(() => import("../pages/product/ProcessLogs"));

function Router() {
  const isSignedIn: boolean = Boolean(
    localStorage.getItem("token") || sessionStorage.getItem("token")
  );
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPsw />} />
        <Route
          path="/product"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Product />
            </Protected>
          }
        />
        <Route
          path="/sku-list"
          element={
            <Protected isSignedIn={isSignedIn}>
              <ProductTable />
            </Protected>
          }
        />
        <Route
          path="/product-details"
          element={
            <Protected isSignedIn={isSignedIn}>
              <ProductDetails />
            </Protected>
          }
        />
        <Route
          path="/setting"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Setting />
            </Protected>
          }
        />
        <Route
          path="/rules"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Rules />
            </Protected>
          }
        />
        <Route
          path="/source/aco"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Aco />
            </Protected>
          }
        />
        <Route
          path="/source/woo-in"
          element={
            <Protected isSignedIn={isSignedIn}>
              <WooApiIn />
            </Protected>
          }
        />
        <Route
          path="/channel/woo-commerce"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Woocommerce />
            </Protected>
          }
        />
        <Route
          path="/channel/shopify"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Shopify />
            </Protected>
          }
        />
         <Route
          path="/process-logs"
          element={
            <Protected isSignedIn={isSignedIn}>
              <ProcessLogs />
            </Protected>
          }
        />
        <Route path="/reset-password/:token" element={<Newpassword />} />
        <Route
          path="*"
          element={
            <Protected isSignedIn={isSignedIn}>
              <NoPage />
            </Protected>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default Router;
