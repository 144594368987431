import { createSlice } from "@reduxjs/toolkit";

const LoaderSlice = createSlice({
  name: "loader",
  initialState: {
    isLoading: true,
  },
  reducers: {
    loader: (state) => {
      state.isLoading = !state.isLoading;
    },
  },
});

export default LoaderSlice;
export const { loader } = LoaderSlice.actions;
