import React from "react"
import { string } from "yup";

//set token in localStorage
export const handleSetToken = (token:string,name:string)=>{
    localStorage.setItem('token',token);
    localStorage.setItem('name',name);
return true;
};

export const handleGetToken = ()=>{
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    return token;
}

export const handleGetName = () => {
    const name = localStorage.getItem('name') || sessionStorage.getItem('name') ;
    return name;
}

//set token in session storage
export const handleSessionToken = (token:string,name:string)=>{
    sessionStorage.setItem('token',token);
    sessionStorage.setItem('name',name);
    return true;
}

//remove token
export const handleRemoveToken = ()=>{
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('name');
    sessionStorage.removeItem('name');

    return true;
}