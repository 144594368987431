import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
  sidebar,
  sidebarClose,
  sidebarOpen,
} from "../../store/sidebarslice/SidebarSlice";

function Sidebar() {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state: any) => {
    return state.sidebar;
  });

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      dispatch(sidebarOpen());
    } else {
      dispatch(sidebarClose());
    }
  }, [dispatch]);

  const closeSidebar = () => {
    dispatch(sidebar());
  };

  return (
    <>
      <div className="sidebar-and-main-flex">
        <section className={data.isOpen ? "sidebar" : "sidebar-none sidebar"}>
          <div className="sidebar-company-logo">
            <img src="/images/company-logo.svg" alt="" />
          </div>
          <div className="navbar-close-button">
            <button onClick={closeSidebar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18.75 5.25L5.25 18.75"
                  stroke="#A0A8B0"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.75 18.75L5.25 5.25"
                  stroke="#A0A8B0"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="sidebar-accordion">
            <div className="sidebar-button">
              <Link to="/product" className="sidebar-routing-link">
                <button
                  onClick={closeSidebar}
                  type="button"
                  className={
                    location.pathname === "/product"
                      ? "active-sidebar-button"
                      : ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M21 16.7193V7.47156C21 7.33848 20.9646 7.2078 20.8974 7.09292C20.8302 6.97805 20.7337 6.88312 20.6177 6.81788L12.3677 2.17725C12.2554 2.1141 12.1288 2.08093 12 2.08093C11.8712 2.08093 11.7446 2.1141 11.6323 2.17725L3.3823 6.81788C3.26632 6.88312 3.16978 6.97805 3.10259 7.09292C3.03541 7.2078 3 7.33848 3 7.47156V16.7193C3 16.8524 3.03541 16.983 3.10259 17.0979C3.16978 17.2128 3.26632 17.3077 3.3823 17.373L11.6323 22.0136C11.7446 22.0767 11.8712 22.1099 12 22.1099C12.1288 22.1099 12.2554 22.0767 12.3677 22.0136L20.6177 17.373C20.7337 17.3077 20.8302 17.2128 20.8974 17.0979C20.9646 16.983 21 16.8524 21 16.7193Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.5958 14.3933V9.51834L7.5 4.50168"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.8967 7.09171L12.0891 12.0954L3.104 7.09077"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.089 12.0954L12.001 22.1099"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t("config.sidebarProduct")}
                </button>
              </Link>
            </div>
            <div className="sidebar-button">
              <Link to="/sku-list" className="sidebar-routing-link">
                <button
                  onClick={closeSidebar}
                  type="button"
                  className={
                    location.pathname === "/sku-list"
                      ? "active-sidebar-button"
                      : ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M21 16.7193V7.47156C21 7.33848 20.9646 7.2078 20.8974 7.09292C20.8302 6.97805 20.7337 6.88312 20.6177 6.81788L12.3677 2.17725C12.2554 2.1141 12.1288 2.08093 12 2.08093C11.8712 2.08093 11.7446 2.1141 11.6323 2.17725L3.3823 6.81788C3.26632 6.88312 3.16978 6.97805 3.10259 7.09292C3.03541 7.2078 3 7.33848 3 7.47156V16.7193C3 16.8524 3.03541 16.983 3.10259 17.0979C3.16978 17.2128 3.26632 17.3077 3.3823 17.373L11.6323 22.0136C11.7446 22.0767 11.8712 22.1099 12 22.1099C12.1288 22.1099 12.2554 22.0767 12.3677 22.0136L20.6177 17.373C20.7337 17.3077 20.8302 17.2128 20.8974 17.0979C20.9646 16.983 21 16.8524 21 16.7193Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.5958 14.3933V9.51834L7.5 4.50168"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.8967 7.09171L12.0891 12.0954L3.104 7.09077"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.089 12.0954L12.001 22.1099"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t("config.sku_route_lable")} 
                </button>
              </Link>
            </div>
            <div className="sidebar-button">
              <Link to="/rules" className="sidebar-routing-link">
                <button
                  onClick={closeSidebar}
                  type="button"
                  className={
                    location.pathname === "/rules"
                      ? "active-sidebar-button"
                      : ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M11.4998 2.52161L3.93846 4.03388L2.42619 11.5952C2.40197 11.7163 2.40802 11.8415 2.4438 11.9597C2.47958 12.0778 2.54398 12.1853 2.63129 12.2727L12.4237 22.0651C12.4934 22.1347 12.5761 22.19 12.6671 22.2277C12.7581 22.2654 12.8556 22.2848 12.9541 22.2848C13.0526 22.2848 13.1501 22.2654 13.2411 22.2277C13.3321 22.19 13.4148 22.1347 13.4844 22.0651L21.9697 13.5798C22.0393 13.5102 22.0946 13.4275 22.1323 13.3365C22.17 13.2455 22.1894 13.148 22.1894 13.0495C22.1894 12.951 22.17 12.8535 22.1323 12.7625C22.0946 12.6715 22.0393 12.5888 21.9697 12.5192L12.1772 2.72671C12.0899 2.63941 11.9824 2.575 11.8642 2.53922C11.7461 2.50344 11.6209 2.49739 11.4998 2.52161V2.52161Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.9375 7.97043C8.9375 8.55723 8.4618 9.03293 7.875 9.03293C7.2882 9.03293 6.8125 8.55723 6.8125 7.97043C6.8125 7.38363 7.2882 6.90793 7.875 6.90793C8.4618 6.90793 8.9375 7.38363 8.9375 7.97043Z"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0.125"
                    />
                  </svg>
                  {t("config.sidebarRules")}
                </button>
              </Link>
            </div>
            <div className="accordion-button-start">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M11.0679 16.4135L14.2498 19.5954L17.4318 16.4135"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.25 12.0954V19.5954"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.99978 19.5954H6.74978C6.00525 19.5947 5.26939 19.4356 4.59104 19.1288C3.91269 18.8219 3.30737 18.3743 2.81526 17.8156C2.32314 17.2569 1.95548 16.5999 1.73669 15.8882C1.5179 15.1766 1.45297 14.4265 1.54623 13.6879C1.63948 12.9492 1.88877 12.2388 2.27757 11.6039C2.66636 10.9689 3.18576 10.4239 3.80129 10.0051C4.41682 9.5862 5.11439 9.30305 5.84772 9.1744C6.58105 9.04575 7.33336 9.07455 8.0547 9.25888"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.5 12.0954C7.50012 10.9071 7.78261 9.73578 8.3242 8.67803C8.86578 7.62028 9.65097 6.70636 10.615 6.01158C11.5791 5.3168 12.6945 4.86105 13.8692 4.68189C15.044 4.50273 16.2445 4.60528 17.3719 4.98109C18.4992 5.35691 19.5211 5.99523 20.3534 6.84345C21.1857 7.69168 21.8045 8.72553 22.1588 9.85981C22.5132 10.9941 22.5929 12.1963 22.3915 13.3675C22.19 14.5386 21.7132 15.6452 21.0002 16.5959"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {t("config.sidebarSource")}
                    </div>
                    <div>
                      <svg
                        className="dropdown-arrow m-0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="10"
                        viewBox="0 0 18 10"
                        fill="none"
                      >
                        <path
                          d="M16.5 1.09543L9 8.59543L1.5 1.09543"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="sub-page-links">
                      <ul>
                        <Link to="/source/aco" className="sidebar-routing-link">
                          <li
                            onClick={closeSidebar}
                            className={
                              location.pathname === "/source/aco"
                                ? "active-sidebar-button"
                                : ""
                            }
                          >
                            {t("config.sidebarAco")}
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M17.4316 14.5L14.2497 11.318L11.0677 14.5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.25 12V19.5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.00002 19.5H6.75002C6.00549 19.4993 5.26963 19.3402 4.59129 19.0333C3.91294 18.7265 3.30762 18.2788 2.8155 17.7201C2.32338 17.1614 1.95573 16.5045 1.73694 15.7928C1.51814 15.0812 1.45322 14.3311 1.54647 13.5924C1.63972 12.8538 1.88902 12.1434 2.27781 11.5084C2.66661 10.8735 3.186 10.3285 3.80153 9.90963C4.41706 9.49077 5.11464 9.20762 5.84797 9.07897C6.5813 8.95032 7.3336 8.97912 8.05495 9.16345"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.5 12.0002C7.50012 10.8119 7.78261 9.6406 8.3242 8.58284C8.86578 7.52509 9.65097 6.61117 10.615 5.9164C11.5791 5.22162 12.6945 4.76587 13.8692 4.58671C15.044 4.40754 16.2445 4.5101 17.3719 4.88591C18.4992 5.26172 19.5211 5.90004 20.3534 6.74827C21.1857 7.59649 21.8045 8.63034 22.1588 9.76463C22.5132 10.8989 22.5929 12.1012 22.3915 13.2723C22.19 14.4434 21.7132 15.55 21.0002 16.5007"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {t("config.sidebarChannel")}
                    </div>
                    <div>
                      <svg
                        className="dropdown-arrow m-0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="10"
                        viewBox="0 0 18 10"
                        fill="none"
                      >
                        <path
                          d="M16.5 1.09543L9 8.59543L1.5 1.09543"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="sub-page-links">
                      <ul>
                        <Link to="/channel/woo-commerce">
                          <li
                            onClick={closeSidebar}
                            className={
                              location.pathname === "/channel/woo-commerce"
                                ? "active-sidebar-button"
                                : ""
                            }
                          >
                            {" "}
                            {t("config.wooCommerce")}
                          </li>
                        </Link>
                        <Link to="/channel/shopify">
                          <li
                            onClick={closeSidebar}
                            className={
                              location.pathname === "/channel/shopify"
                                ? "active-sidebar-button"
                                : ""
                            }
                          >
                            {" "}
                            {t("config.shopify")}
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="sidebar-button">
              <Link to="/process-logs" className="sidebar-routing-link">
                <button
                  onClick={closeSidebar}
                  type="button"
                  className={
                    location.pathname === "/process-logs"
                      ? "active-sidebar-button"
                      : ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M21 16.7193V7.47156C21 7.33848 20.9646 7.2078 20.8974 7.09292C20.8302 6.97805 20.7337 6.88312 20.6177 6.81788L12.3677 2.17725C12.2554 2.1141 12.1288 2.08093 12 2.08093C11.8712 2.08093 11.7446 2.1141 11.6323 2.17725L3.3823 6.81788C3.26632 6.88312 3.16978 6.97805 3.10259 7.09292C3.03541 7.2078 3 7.33848 3 7.47156V16.7193C3 16.8524 3.03541 16.983 3.10259 17.0979C3.16978 17.2128 3.26632 17.3077 3.3823 17.373L11.6323 22.0136C11.7446 22.0767 11.8712 22.1099 12 22.1099C12.1288 22.1099 12.2554 22.0767 12.3677 22.0136L20.6177 17.373C20.7337 17.3077 20.8302 17.2128 20.8974 17.0979C20.9646 16.983 21 16.8524 21 16.7193Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.5958 14.3933V9.51834L7.5 4.50168"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.8967 7.09171L12.0891 12.0954L3.104 7.09077"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.089 12.0954L12.001 22.1099"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t("Logs")}
                </button>
              </Link>
            </div>
          </div>
        </section>
        {/* <div className="header-and-main-content">
      

        </div> */}
      </div>
    </>
  );
}

export default Sidebar;
