// i18n.js
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import eslng from './es-lng.json';
import enlng from './en-lg.json';
const resources = {
  en: {
    translation: { config: enlng },
  },
  es: {
    translation: { config: eslng },
  },
  // Add more languages and translations as needed
};

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Set the default language
    fallbackLng: 'en', // Fallback language if a translation is missing
    // interpolation: {
    //   escapeValue: false,
    // },
    // ns: ['translations'],
    // defaultNS: 'translations'
  });
// i18n.languages = ['en', 'es'];
i18n.changeLanguage('en');


export default i18n;
